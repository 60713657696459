import request from '@/utils/request'

/**
  * 获取oss配置
  */
export function uploadConfigApi(data = {}) {
  return request({
    url: 'app/upConf',
    method: 'POST',
    data
  })
}