import store from "@/store/index.js"
import { uploadConfigApi } from '@/api/app.js'
const OSS = require('ali-oss');

function convertOssUrl(key) {
	let uploadConfig = store.state.uploadConfig
	const client = new OSS({
		// 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
		accessKeyId: uploadConfig.accessKeyId,
		accessKeySecret: uploadConfig.accessKeySecret,
		// 从STS服务获取的安全令牌（SecurityToken）。
		stsToken: uploadConfig.securityToken,
		// refreshSTSToken: async () => {
		//   // 向您搭建的STS服务获取临时访问凭证。
		//   const info = await fetch('your_sts_server');
		//   return {
		//     accessKeyId: info.accessKeyId,
		//     accessKeySecret: info.accessKeySecret,
		//     stsToken: info.stsToken
		//   }
		// },
		// 刷新临时访问凭证的时间间隔，单位为毫秒。
		refreshSTSTokenInterval: 300000,
		// 填写Bucket名称，例如examplebucket。
		bucket: uploadConfig.bucket,
		// yourEndpoint填写Bucket所在地域对应的Endpoint。以华东1（杭州）为例，Endpoint填写为https://oss-cn-hangzhou.aliyuncs.com。
		endpoint: uploadConfig.endPoint
	});
	// 生成带图片处理参数的文件签名URL，并设置签名URL的过期时间为600秒。
	const signUrl = client.signatureUrl(key.replace('oss://',''), { expires: 600 });
	return {
    url: signUrl
  }
}

export function ossKeyToUrl({key}) {
  return new Promise((resolve, reject) => {
    if(key && key.startsWith('oss')) {
			if (store.state.uploadConfig && store.state.uploadConfig.expireTime > Date.now()) {
				resolve(convertOssUrl(key))
			} else {
				uploadConfigApi().then(res => {
					store.commit('SET_UPLOAD_CONFIG', res.data)
					resolve(convertOssUrl(key))
				}).catch(err => {
					reject(err)
				})
			}
		} else {
			resolve({url: key})
		}
  })
}
